
import AbstractComponentVue from '../services/AbstractComponentVue';
import {Component, Prop, Watch} from 'vue-property-decorator';
import {ISigInputTextDto} from '@/components/user/ISigInputTextDto';
import SigInputTexte from '@/components/user/SigInputTexte.vue';
import PatternUtils from '@/utils/PatternUtils';

@Component({
  components: {
	  SigInputTexte,
  },
})
export default class EmailsSelection extends AbstractComponentVue {
  private patternUtils = PatternUtils;
  @Prop() private emails!: string[];
  @Prop() private displayManualMail!: boolean;
  @Prop() private rescueEmail!: string;
  private manualEmail: ISigInputTextDto = {value: '', hasError: false, msgError: ''};
  private selectedEmail: string = '';

  public updateSelectedEmail(newMail: string): string {
    return this.selectedEmail = newMail;
  }

  public get showRescueEmail(): boolean {
    return this.displayManualMail && this.rescueEmail !== undefined && this.rescueEmail !== null;
  }

  /**
   * watcher sur la saisie du email manuel
   * @param email de l'email saisit manuellement
   */
  private watchManualEmail(email: ISigInputTextDto) {
    this.manualEmail.value = !email.hasError ? email.value.trim() : '';
    this.selectedEmail = !email.hasError ? email.value.trim() : '';
    this.$emit('selectedEmailChanged', this.selectedEmail);
  }

  @Watch('selectedEmail')
  private emitSelectedEmail() {
    this.$emit('selectedEmailChanged', this.selectedEmail);
  }
}
